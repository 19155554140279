import React, { memo } from 'react'
import { images } from 'config/images'
import LazyLoad from 'react-lazyload'
import { DevelopmentApproachStyle } from './DevlopmentApproach.styles'

const DevlopmentApproach = memo(
  ({ devlomentapproachdata: { devApproachProps } }) => {
    return (
      <DevelopmentApproachStyle className="development-approach-section">
        <div className="container">
          <div className="heading-wrapper">
            <h3>{devApproachProps.heading}</h3>
          </div>
          <div className="approach-listing">
            {devApproachProps.approachContents.map(approachContent => {
              return (
                <div
                  key={approachContent.approachBlkClass}
                  className={`approach-block ${approachContent.approachBlkClass}`}
                >
                  <div className="img-part">
                    <img
                      src={approachContent.approachImgSrc}
                      alt={approachContent.approachImgAlt}
                    />
                  </div>
                  <div className="content-part">
                    <h4>{approachContent.approachHeading}</h4>
                    <p>{approachContent.approachContent}</p>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="adopting-img">
            <LazyLoad height={350} once offset={2000}>
              <img src={images.adoptingProcessMen} alt="Procees Man" />
            </LazyLoad>
          </div>
        </div>
      </DevelopmentApproachStyle>
    )
  }
)

export default DevlopmentApproach
