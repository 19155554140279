import styled from 'styled-components'
import {
  lg,
  sm,
  md,
  themeColor,
  device,
  primaryColor,
  xl,
  white,
  sh,
} from 'config/variables'

export const FromOurExpertsSecStyle = styled.div`
  margin-top: 107px;
  margin-bottom: 100px; 
  ${sh(`
    margin-top: 80px;
    margin-bottom: 80px;
	`)}
  ${xl(`
    margin-top: 80px;
    margin-bottom: 80px;
	`)}
  ${lg(`
    margin-top: 60px;
    margin-bottom: 60px;
  `)}
  ${md(`
    margin-top: 40px;
    margin-bottom: 40px;
  `)}
  .expertCardRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    margin-top: 50px;
    ${xl(`
      margin: 0 -15px;
      margin-top: 50px;
    `)}
    ${md(`
      margin-top: 40px;
    `)}
  }
`

export const SingleExpertCardStyle = styled.div`
  width: 25%;
  min-width: 25%;
  flex: 0 1 25%;
  padding: 20px;
  display: flex;
  flex-direction: column; 
  ${lg(`
    width: 50%;
    min-width: 50%;
    flex: 0 1 50%; 
    padding: 15px;
  `)}
  ${sm(`
    width: 100%;
    min-width: 100%;
    flex: 0 1 100%;
  `)}
  &.width33 {
    width: 33.33%;
    min-width: 33.33%;
    flex: 0 1 33.33%;
    margin: 0 auto;
    ${md(`
      width: 50%;
      min-width: 50%;
      flex: 0 1 50%; 
      padding: 15px;
    `)}
    ${sm(`
      width: 100%;
      min-width: 100%;
      flex: 0 1 100%;
    `)}
  }
  &.horizontalCard {
    width: 50%;
    min-width: 50%;
    flex: 0 1 50%; 
    ${lg(`
      width: 100%;
      min-width: 100%;
      flex: 0 0 100%; 
    `)}
    .cardThumb {
      flex: 0 0 200px;
      max-width: 200px;
      width: 200px;
      filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.3));
      ${sm(`
        flex: 0 0 130px;
        max-width: 130px;
        width: 130px;
      `)}
      img {
        display: block;
      }
    }
    .expertCard {
      flex-direction: row;
      padding: 30px 17px 30px 30px;
      ${xl(`
        padding: 15px;
      `)}
      ${lg(`
        padding: 15px;
      `)}
      ${sm(`
        padding: 15px 10px;
      `)}
    }
    .content-part {
      padding: 0 0 0 30px;
      ${xl(`
        padding: 0 0 0 15px;
      `)}
      ${lg(`
        padding: 0 0 0 15px;
      `)}
      ${sm(`
        padding: 0 0 0 10px;
      `)}
    }
  }
  .expertCard {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: ${white};
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.05),
      0 3px 5px -1px rgba(0, 0, 0, 0.07);
    transition: all 0.4s ease;
    &:hover {
      box-shadow: 4px 4px 26px 5px rgba(0, 0, 0, 0.05),
        6px 8px 26px 0px rgba(0, 0, 0, 0.02);
      transform: translate(0px, -10px);
      ${device(`
        box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.05), 0 3px 5px -1px rgba(0, 0, 0, 0.07);
        transform: initial;
      `)} 
    }
  }
  figure {
    width: 100%;
    display: block;
    
  }
  .content-part {
    padding: 17px 20px 20px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    ${xl(`
        padding: 15px;
    `)}
    ${lg(`
        padding: 15px;
    `)}
    ${sm(`
       padding: 15px 10px;
    `)}
    h4 {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.22px;
      font-weight: 500;
      margin-bottom: 10px;
      transition: all 0.3s ease;
      color: ${primaryColor};
      ${sm(`
        font-size: 16px;
        line-height: 20px;
      `)}
    }
    p {
      font-size: 16px;
      line-height: 28px;
      color: ${primaryColor};
      width:100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      ${sm(`
        font-size: 14px;
        line-height: 20px;
      `)}
    }
    .line {
      width: 74px;
      height: 2px;
      display: block;
      background: ${themeColor};
      margin-bottom: 14px;
      margin-top: auto;
      ${sm(`
        width: 60px;
        margin-bottom: 10px;
      `)}
    }
    .btn-readmore {
      font-size: 18px;
      color: ${themeColor};
      line-height: 24px;
      font-weight: 500;
      display: inline-block;
      position: relative;
      ${sm(`
         font-size: 16px;
        line-height: 20px;
      `)}
    }
  }
`
