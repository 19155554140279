import React from 'react'
import {
  TechPartnersSecStyle,
  SingleExpertCardStyle,
} from './TechPartnersSec.styles'
import Image from 'components/ImgOptimized'
import Button from 'components/Button/Button'
import { images } from 'config/images'

function TechPartnersSec(props) {
  const { TechPartnersSecData } = props

  return (
    <TechPartnersSecStyle>
      <div className="container">
        <div className="heading">
          <h2>{TechPartnersSecData.TechPartnersTitle}</h2>
          <div className="leftImageItem">
            <figure className="cardThumb">
              <Image
                fluid={props.partnersLeftlogo}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 150,
                }}
              />
            </figure>
          </div>
        </div>
        <div className="techPartnersDetails">
          <div className="techPartnersLogoRow">
            <div className="rightImageItem">
              <div className="logo-row">
                {props.Partnerslogo.map((item, i) => {
                  return (
                    <div key={i} className="imageItem">
                      <figure className="cardThumb">
                        <Image
                          fluid={props.Partnerslogo[i]}
                          lazyload={{
                            enabled: true,
                            once: true,
                            offset: 1000,
                            heigth: 210,
                          }}
                        />
                      </figure>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="certified-individuals">
          <span className="logo-icon">
            <img src={images.awsLogo} alt="Aws" />
          </span>
          <span className="info">
            <strong>100+</strong> AWS Certified Individuals
          </span>
        </div>
        <div className="techPartnersCTA">
          {TechPartnersSecData.partnersButtonText && (
            <Button
              bgFillDark
              type={TechPartnersSecData.type}
              buttonLink={TechPartnersSecData.partnersButtonLink}
              buttonName={TechPartnersSecData.partnersButtonText}
            />
          )}
        </div>
      </div>
    </TechPartnersSecStyle>
  )
}

export default TechPartnersSec
