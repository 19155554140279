import styled from 'styled-components'
import {
  lg,
  sm,
  md,
  themeColor,
  device,
  primaryColor,
  xl,
  white,
  sh,
} from 'config/variables'

export const TechPartnersSecStyle = styled.div`
  margin-top: 107px;
  margin-bottom: 100px; 
  background-image: linear-gradient(to bottom, rgba(242, 220, 204, 0), #f8f9ff);
  padding-bottom: 80px; 
  .heading {
    display: flex;
    justify-content: space-between;
    ${lg(`
        flex-direction: column;
        align-items: center;
    `)}
    h2 {
      flex-grow: 1 ;
      max-width: 525px;
      margin-right: 45px;
      margin-bottom: 0;
      line-height: 51px;
      ${lg(`        
        max-width: unset;
        margin: 0 auto 30px auto;
        text-align:center;
      `)}
      ${md(`
        font-size: 36px;
        line-height: 42px;
      `)}
    }
    .leftImageItem {
      flex-grow:1;
      max-width: 450px;
      padding: 20px;
      box-shadow: -3.1753px 4.23373px 6.3506px rgba(153, 167, 216, 0.2);
      border-radius: 4px;
      ${lg(`        
        width: 100%;
      `)}
    }
  }
  ${sh(`
    margin-top: 80px;
    margin-bottom: 80px;
	`)}
  ${xl(`
    margin-top: 80px;
    margin-bottom: 80px;
	`)}
  ${lg(`
    margin-top: 60px;
    margin-bottom: 60px;
    padding-bottom: 60px;
  `)}
  ${md(`
    margin-top: 40px;
    margin-bottom: 40px;
    padding-bottom: 40px;
  `)}
  .techPartnersDetails {
    display: flex;  
    flex-direction:column;
    margin-top: 50px;
    align-items: center; 
    ${xl(` 
      margin-top: 50px;
    `)}
    ${md(`
      margin-top: 40px;
    `)} 
    .techPartnersInfoCol { 
      flex: 1 1 auto;
      width: 100%;
      text-align:center;
      max-width: 840px;
      margin: 0 auto;
      p {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: -0.15px;
        margin-bottom: 0;
        ${md(`        
          font-size: 18px;
          line-height: 28px; 
        `)}
      }
    }
    .techPartnersLogoRow {
      width: 100%;
      display: flex;
      justify-content:center;
      align-items:center; 
      ${md(`        
        align-items:flex-start; 
      `)}
      .leftImageItem {
        flex: 0 0 240px;
        max-width:240px;
        width:240px;
        ${lg(`        
          flex: 0 0 200px;
          max-width:200px;
          width:200px;
        `)}
        ${sm(`        
          flex: 0 0 160px;
          max-width:160px;
          width:160px;
        `)}
        .cardThumb {
          background: ${white};
          box-shadow: 0px 4px 6px rgba(153, 167, 216, 0.2);
        }
      }
      .rightImageItem {
        flex: 1 1 auto;
        ${lg(`        
          padding-left: 30px; 
        `)}
        ${sm(`        
          padding-left: 20px;
        `)}
        .logo-row {
          display:flex;
          flex-wrap:wrap;
          margin: 0 -20px;
          .imageItem { 
            width: 16.666%;
            max-width: 16.666%;
            flex:0 0 16.666%;
            padding: 0 20px;
            margin:10px 0;
            ${md(`        
              width: 33.33%;
              max-width: 33.33%;
              flex:0 0 33.33%;
              margin:0 0 20px;
            `)}
            ${sm(`        
              width: 50%;
              max-width: 50%;
              flex:0 0 50%;
              margin:0 0 10px;
            `)}
            .cardThumb {
              margin: auto;
              filter: drop-shadow(-4px 2px 6px rgba(153,167,216,0.4));
            }
          }
        }
      }
    }
  }
  .certified-individuals {
    border-top:1px solid #C7D1F8;
    padding: 14px 0 0;
    margin-top:30px;
    display: flex;
    align-items:center;
    justify-content:center; 
    ${sm(`        
      margin-top:20px;
      padding: 10px 0 0;
    `)}
    .info {
      font-size: 18px;
      line-height: 37px;
      color: ${primaryColor};
      ${sm(`        
        font-size: 15px;
        line-height: 26px;
      `)}
      strong {
        font-size: 32px;
        line-height: 40px; 
        ${sm(`        
          font-size: 22px;
          line-height: 30px;
        `)}
      }
    }
    .logo-icon {
      margin-top:10px;
      margin-right:25px;
      display: flex;
      ${sm(`        
        margin-right:10px;
        max-width:40px;
      `)}
    }
  }
  .techPartnersCTA {
    margin-top: 40px;
    text-align:center;
    ${xl(` 
      margin-top: 40px;
    `)}
    ${md(`
      margin-top: 30px;
    `)}
  }
`
