import styled from 'styled-components'
import { sm, lg, xl, md, sh } from 'config/variables'

export const DevelopmentApproachStyle = styled.section`
  background-image: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f8f9ff);
  margin-bottom: 80px;
  ${md(`
    margin-bottom: 60px;
  `)}
  .container {
    position: relative;
    padding: 0 40px 153px;
    ${sh(`
      padding: 0 40px 133px;
    `)}
    ${sm(`
      padding: 0px 20px 165px;
      `)}
    .approach-listing {
      margin: 0 auto;
      margin-top: 50px;
      display: flex;
      flex-wrap: wrap;
      border: 1px solid rgba(194, 197, 212, 0.3);
      border-right: 0;
      border-bottom: 0;
      background: rgba(252, 253, 255, 0.5);
      ${lg(`
        max-width: 610px;
        `)}
      ${md(`
        margin-top: 40px;
      `)}
      .approach-block {
        width: 50%;
        border: 1px solid rgba(194, 197, 212, 0.3);
        border-left: 0;
        border-top: 0;
        display: flex;
        padding: 32px 26px;
        ${sh(`
          padding: 29px 24px;
        `)}
        ${xl(`
          padding: 30px;
          `)}
        ${lg(`
          width: 100%;
          padding: 30px;
          `)}
        ${sm(`
          padding: 20px;
          flex-wrap: wrap;
          `)}
        &.sage-green {
          .content-part {
            h4 {
              color: #85c26a;
            }
          }
        }
        &.carnation {
          .content-part {
            h4 {
              color: #ef7c91;
            }
          }
        }
        &.pale-orange {
          .content-part {
            h4 {
              color: #f6a25f;
            }
          }
        }
        &.sky-blue {
          .content-part {
            h4 {
              color: #6cbaf7;
            }
          }
        }
        &.robin-s-egg {
          .content-part {
            h4 {
              color: #63d5ff;
            }
          }
        }
        &.perrywinkle {
          .content-part {
            h4 {
              color: #8d7fd8;
            }
          }
        }
        .img-part {
          width: 50px;
          height: 50px;
          margin-right: 24px;
          ${sh(`
            width: 45px;
            height: 45px;
            margin-right: 21px;
          `)}
          ${sm(`
            margin-bottom: 15px;
            `)}
        }
        .content-part {
          flex: 1;
          ${sm(`
            flex: 100%;
            `)}
          h4 {
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 7px;
            ${sh(`
              font-size: 19px;
              line-height: 27px;
            `)}
            ${sm(`
              font-size: 20px;
              line-height: 28px;
              `)}
          }
          p {
            font-size: 18px;
            line-height: 24px;
            margin: 0;
            ${sh(`
              font-size: 16px;
              line-height: 22px;
            `)}
            ${md(`
              font-size: 16px;
              line-height: 26px;
              `)}
            ${sm(`
              font-size: 16px;
              line-height: 26px;
              `)}
          }
        }
      }
    }
    .adopting-img {
      position: absolute;
      bottom: 0;
      left: -120px;
      ${sh(`
        left: -100px;
        max-width: 240px;
      `)}
      ${xl(`
        left: -30px;
        width: 200px;
        `)}
      ${lg(`
        left: -30px;
        width: 230px;
        `)}
        ${sm(`
          width: 170px;
          `)}
    }
  }
`
