import React from 'react'
import {
  FromOurExpertsSecStyle,
  SingleExpertCardStyle,
} from './FromOurExpertsSec.styles'
import Image from 'components/ImgOptimized'
import { Link } from 'gatsby'
import { EXTERNAL_LINK } from 'config/utils'

export const SingleExpertCard = props => {
  return (
    <SingleExpertCardStyle
      className={`${props.HorizontalCard ? 'horizontalCard' : ''} ${
        props.ThreeBlogCard ? 'width33' : ''
      }`}
    >
      <div className="expertCard">
        <figure className="cardThumb">
          <Image
            fluid={props.ImageThumb}
            lazyload={{
              enabled: true,
              once: true,
              offset: 1000,
              heigth: 210,
            }}
          />
        </figure>
        <div className="content-part">
          <h4>{props.cardTitle}</h4>
          {props.cardDetails && <p>{props.cardDetails}</p>}
          <span className="line" />
          {props.linkType !== EXTERNAL_LINK ? (
            <Link className="btn-readmore" to={props.cardLink}>
              {props.cardLinkText}
            </Link>
          ) : (
            <a
              className="btn-readmore"
              target={props.openNewTab ? '_blank' : ''}
              href={props.cardLink}
            >
              {props.cardLinkText}
            </a>
          )}
        </div>
      </div>
    </SingleExpertCardStyle>
  )
}

function FromOurExpertsSec(props) {
  const { FromOurExpertsSecData } = props
  return (
    <FromOurExpertsSecStyle>
      <div className="container">
        <div className="heading-wrapper">
          <h2>{FromOurExpertsSecData.FromOurExpertsTitle}</h2>
        </div>
        <div className="expertCardRow">
          {FromOurExpertsSecData.FromOurExpertsContent.map(
            (expertsContent, i) => {
              return (
                <SingleExpertCard
                  key={i}
                  linkType={expertsContent.linkType}
                  cardLink={expertsContent.cardLink}
                  ImageThumb={props.ImageThumb[i]}
                  cardTitle={expertsContent.cardTitle}
                  cardDetails={expertsContent.cardDetails}
                  cardLinkText={expertsContent.cardLinkText}
                  HorizontalCard={expertsContent.HorizontalCard}
                  ThreeBlogCard={expertsContent.ThreeBlogCard}
                  openNewTab={expertsContent.openNewTab}
                />
              )
            }
          )}
        </div>
      </div>
    </FromOurExpertsSecStyle>
  )
}

export default FromOurExpertsSec
